import { defaultAbiCoder } from '@ethersproject/abi'
import { getCreate2Address } from '@ethersproject/address'
import { keccak256 } from '@ethersproject/solidity'
import { Token } from '@uniswap/sdk-core'
import { FeeAmount, POOL_INIT_CODE_HASH, POOL_INIT_CODE_HASH_OPTIMISM, POOL_INIT_CODE_HASH_OPTIMISM_KOVAN } from '../constants'

/**
 * Computes a pool address
 * @param factoryAddress The Uniswap V3 factory address
 * @param tokenA The first token of the pair, irrespective of sort order
 * @param tokenB The second token of the pair, irrespective of sort order
 * @param fee The fee tier of the pool
 * @returns The pool address
 */
export function computePoolAddress({
    factoryAddress,
    tokenA,
    tokenB,
    fee,
    initCodeHashManualOverride
}: {
    factoryAddress: string
    tokenA: Token
    tokenB: Token
    fee: FeeAmount
    initCodeHashManualOverride?: string
}): string {
    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks
    return getCreate2Address(
        factoryAddress,
        keccak256(
            ['bytes'],
            [defaultAbiCoder.encode(['address', 'address'], [token0.address, token1.address])]
        ),
        initCodeHashManualOverride ??
        (tokenA.chainId === 10
            ? POOL_INIT_CODE_HASH_OPTIMISM
            : tokenA.chainId === 69
                ? POOL_INIT_CODE_HASH_OPTIMISM_KOVAN
                : POOL_INIT_CODE_HASH)
    )
}
